<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 190px)">
        <div class="row mx-0 f-18 text-general f-600 mt-3">
            <div class="col">
                Cuentas por pagar en los próximos 3 meses
            </div>
        </div>
        <graficaPorPagarTresMeses />
        <div v-loading="loading" class="row mx-0 mt-4 justify-center">
            <div class="col-4 px-1">
                <div class="row mx-0 h-100 align-items-center">
                    <div class="col border mx-1 bg-light-f5 text-general box-shadow-29 br-12 py-2">
                        <p class="text-center f-15">
                            Cuotas Vencidas
                        </p>
                        <p class="text-center f-18 f-600">
                            {{ info.cuotas_vencidas }}
                        </p>
                    </div>
                    <div class="col border mx-1 bg-light-f5 text-general box-shadow-29 br-12 py-2">
                        <p class="text-center f-15">
                            Deuda vencida
                        </p>
                        <p class="text-center f-18 f-600">
                            {{ separadorNumero(info.deuda_vencidas) }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-3 px-1 mx-1 bg-light-f5 text-general border box-shadow-29 br-12 py-3">
                <p class="text-center f-15">
                    Deuda total
                </p>
                <p class="text-center f-18 f-600">
                    {{ separadorNumero(info.deuda_total) }}
                </p>
            </div>
            <div class="col-4 px-1">
                <div class="row mx-0 h-100 align-items-center">
                    <div class="col border mx-1 bg-light-f5 text-general box-shadow-29 br-12 py-2">
                        <p class="text-center f-15">
                            Próximas Cuotas
                        </p>
                        <p class="text-center f-18 f-600">
                            {{ info.proxi_cuotas }}
                        </p>
                    </div>
                    <div class="col border mx-1 bg-light-f5 text-general box-shadow-29 br-12 py-2">
                        <p class="text-center f-15">
                            Deuda por pagar
                        </p>
                        <p class="text-center f-18 f-600">
                            {{ separadorNumero(info.deuda_por_cobrar) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 f-18 text-general mt-5">
            <div class="col-12 f-600">
                Cuentas pendientes
            </div>

            <div class="col">
                <tabla-general v-loading="loading" :data="tableData" :columnas="dataColumnas">
                    <template slot="adicionales-izquierda">
                        <el-table-column prop="id_pedido_admin" label="Pedido" sortable min-width="90">
                            <template slot-scope="scope">
                                <!-- Redireccion a ver vista -->
                                <router-link :to="{name: 'tendero.almacen.creditos.cuotas.ver', params: { id_pedido: scope.row.id_pedido_admin }}" class="text-general" style="text-decoration:none;">
                                    <!-- <div class="cr-pointer" @click="AbrirResgistrarPago(scope.row)"> -->
                                    {{ scope.row.id_pedido_admin }}
                                <!-- </div> -->
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="valor_final" label="Total" sortable min-width="100" class-name="text-right">
                            <template slot-scope="scope">
                                {{ separadorNumero(scope.row.valor_final) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at_pedido" label="Fecha del pedido" sortable min-width="180" class-name="text-center">
                            <template slot-scope="scope">
                                <p>{{ formatearFecha(scope.row.created_at_pedido,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="valor" label="Cuota" sortable min-width="100" class-name="text-right">
                            <template slot-scope="scope">
                                <div class="cr-pointer" @click="AbrirResgistrarPago(scope.row)">
                                    {{ separadorNumero(scope.row.valor) }}
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-slot:adicionales-derecha>
                        <el-table-column prop="fecha" label="Fecha Cuota" sortable min-width="180" class-name="text-right">
                            <template slot-scope="scope">
                                <p :class="scope.row.vencido?'text-danger-fecha': ''">{{ formatearFecha(scope.row.fecha,'DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Modales -->
        <modalRegistrarPagos ref="RegistrarPago" />
    </section>
</template>

<script>
import Creditos from '~/services/almacen/creditos'
import {mapGetters} from 'vuex'


export default {
    components: {
        modalRegistrarPagos: () => import('~/pages/almacen/creditos/partials/modalRegistrarPago'),
        graficaPorPagarTresMeses: () => import('./components/graficaPorPagarTresMeses')

    },
    data(){
        return{
            loading: false,
            dataColumnas: [
                'id_pedido_admin','valor_final','valor','fecha','created_at_pedido'
            ],
            // info:{
            //     cuotas_vencidas: 0,
            //     deuda_por_cobrar: 0,
            //     deuda_vencidas: 0,
            //     proxi_cuotas: 0,
            //     deuda_total: 0
            // },
            /* tableData: [
                { nombre: 'OXXO', deuda: '$ 534.000', valor_pedido: '01 Jul. 2018', fecha_pedido: '01 Jul. 2018', proximo_pago: '05 Jul. 2008' },
            ], */
        }
    },
    computed:{
        ...mapGetters({
            info: 'almacen/credito_tendero/infoTabCuotas',
            tableData: 'almacen/credito_tendero/tablaCuotas'
        }),
    },
    mounted(){
        this.listarCuotas()
    },
    methods: {
        AbrirResgistrarPago(row){
            this.$refs.RegistrarPago.toggle(row, 1, true); //  true para que sea modo ver
        },
        async listarCuotas(){
            try {

                this.loading = true
                await this.$store.dispatch('almacen/credito_tendero/action_listar_cuotas')
                this.loading = false
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.box-shadow-29{
    box-shadow: 0px 3px 6px #00000029;
}
.br-12{
    border-radius: 12px;
}
.text-danger-fecha{
    color: #FF0000;
}
</style>
